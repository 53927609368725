body {
  margin: 0;
  font-family: "Karla", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: aliceblue;
}

/* Sticky styles */
@media (max-width: 643px) {
  main {
    margin-top: 78px;
  }
}

@media (max-width: 543px) {
  main {
    margin-top: 60px;
  }
}

@media (max-width: 430px) {
  main {
    margin-top: 57.5px;
  }
}

html {
  background-color: aliceblue;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 0.8s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
